import React from 'react';
import Footer from '../components/Footer'

import CTA from '../components/CTA'
import HeroMobile from '../assets/Hero-mobile.mp4'
import Hero from '../assets/Hero-Video-3.mp4'
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Ribbon from '../components/Ribbon'
import alehouse from '../images/alehouse.jpg'
import crystalcoast from '../images/crystalcoast.jpg'
import just from '../images/just.jpg'
import progressive from '../images/progressive.jpg'
import washington from '../images/washington.jpg'
import uncg from '../images/uncg.jpg'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'



const toggleTerm = term => {
 let activeTerm = document.getElementById(term);
 let state = getComputedStyle(activeTerm).getPropertyValue(`--services-${term}-display`)

 if(state === 'none') {
  ['media', 'marketing', 'technology'].forEach(item => document.getElementById(item).style.setProperty(`--services-${item}-display`, 'none'));
  activeTerm.style.setProperty(`--services-${term}-display`, 'block')

 } else {
  ['media', 'marketing', 'technology'].forEach(item => document.getElementById(item).style.setProperty(`--services-${item}-display`, 'none'));
  activeTerm.style.setProperty(`--services-${term}-display`, 'none')


 }

}

export default () => (
  <div id="app">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description"
            content="WRAL Digital Solutions is a full-service digital marketing agency based in Raleigh, NC.
            Contact our team today to see how we can help you.">
          </meta>
          <title>Raleigh Digital Marketing Agency | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/" />
        </Helmet>

    <PreHeader/>
    <Header/>
    <Ribbon />

    <div className="hero_home">
      <video className="hero-video mobile" autoPlay playsInline muted loop>
        <source src = {HeroMobile} type="video/mp4" />
        <track kind="captions" srcLang="en"></track>
      </video>
      <video className="hero-video desktop" autoPlay playsInline muted loop>
        <source src = {Hero} type="video/mp4" />
        <track kind="captions" srcLang="en"></track>
      </video>
    </div>
    <div className="sign-background-overlay">
    <div className="sign-background">
      <div id="about" className="about">
        <div className="atf">
          <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--mobile"/>
        </div>
        <div className="tagline--mobile">
        <p className="tagline_1">Sitting at the intersection of <span className='primaryHue'>media,</span> <span className='primaryHue'>marketing,</span> and <span className='primaryHue'>technology,</span> WRAL Digital Solutions is a full-service <h1 className="tagline-inline">digital marketing agency in Raleigh</h1>, helping our clients tell their story to the right people, in the right place, at the right time.</p>
        </div>
        <div className="tagline--desktop">
        <p className="tagline_1">Sitting at the intersection of <span className='primaryHue'>media,</span> <span className='primaryHue'>marketing,</span> and <span className='primaryHue'>technology,</span> WRAL Digital Solutions is a full-service <h1 className="tagline-inline">digital marketing agency in Raleigh</h1>, helping our clients tell their story to the right people, in the right place, at the right time.</p>
        </div>
        <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--desktop"/>
      </div>
      <div id="work" className="work">
        <h3 className="work_title">Our Work</h3>
        <p className="work_tagline">A few of our recent projects.</p>
        <div className="work_gallery">
          <div className="work_gallery--alehouse">
            <img src = {alehouse} alt ="alehouse" />
            <Link to="/carolina-ale-house/">
              <div className="overlay">
                <span>Carolina Ale House</span>
              </div>
            </Link>
          </div>
          <div className="work_gallery--crystalcoast">
            <img src = {crystalcoast} alt ="crystalcoast" />
            <Link to="/crystal-coast/">
              <div className="overlay">
                <span>Crystal Coast</span>
              </div>
            </Link>
          </div>
          <div className="work_gallery--just">
            <img src = {just} alt ="just" />
            <Link to="/just-podcast/">
              <div className="overlay">
                <span>JUST Podcast</span>
              </div>
            </Link>
          </div>
          <div className="work_gallery--progressive">
            <img src = {progressive} alt ="progressive" />
            <Link to="/progressive-heating/">
              <div className="overlay">
                <span>Progressive Service Company</span>
              </div>
            </Link>
          </div>
          <div className="work_gallery--washington">
            <img src = {washington} alt ="little washington" />
            <Link to="/little-washington/">
              <div className="overlay">
                <span>Little Washington</span>
              </div>
            </Link>
          </div>

          <div className="work_gallery--uncg">
            <img src = {uncg} alt ="uncg" />
            <Link to="/uncg/">
              <div className="overlay">
                <span>UNCG</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div id="service" className="services">
      <h3 className="services_title">Our Services</h3>
      <p className="services_blurb">
          WRAL Digital Solutions is a trusted partner, combining exclusive
          advertising opportunities on WRAL.com with a full complement of
          digital marketing and creative services, driving transparent and data
          driven results for our clients.
      </p>
      <div className="services_grid">
        <div className="services_media">
          <h3 onClick={toggleTerm.bind(this, 'media')} className="services--media" >Media</h3>
          <ul id="media" className="services--media_info">
            <li className="services--media_info-item">Targeted Display</li>
            <li className="services--media_info-item">Newsletters and Emails</li>
            <li className="services--media_info-item">Social Media</li>
            <li className="services--media_info-item">Sponsorships</li>
            <li className="services--media_info-item">Video Advertising</li>
            <li className="services--media_info-item">Rich Media</li>
            <li className="services--media_info-item">Branded Content</li>
            <li className="services--media_info-item">Over the Top</li>
            <li className="services--media_info-item">Contests and Polling</li>
          </ul>
        </div>
        <div className="services_marketing">
          <h3 onClick={toggleTerm.bind(this, 'marketing')} className="services--marketing">Marketing</h3>
          <ul id="marketing" className="services--marketing_info">
            <li className="services--marketing_info-item">Social Media Strategy</li>
            <li className="services--marketing_info-item">SEO</li>
            <li className="services--marketing_info-item">Pay-Per-Click</li>
            <li className="services--marketing_info-item">Targeted Emails</li>
            <li className="services--marketing_info-item">Programmatic Advertising</li>
            <li className="services--marketing_info-item">Streaming Audio</li>
            <li className="services--marketing_info-item">Graphic Design</li>
            <li className="services--marketing_info-item">Copywriting</li>
            <li className="services--marketing_info-item">Brand Identity and Strategy</li>
          </ul>
        </div>
        <div className="services_technology">
          <h3 onClick={toggleTerm.bind(this, 'technology')} className="services--technology">Technology</h3>
          <ul id="technology" className="services--technology_info">
            <li className="services--technology_info-item">Web Design / App Design</li>
            <li className="services--technology_info-item">Website Development</li>
            <li className="services--technology_info-item">Web App / PWA Development</li>
            <li className="services--technology_info-item">UI + UX Design</li>
            <li className="services--technology_info-item">Web Hosting</li>
            <li className="services--technology_info-item">Video Production</li>
            <li className="services--technology_info-item">Web Analytics</li>
          </ul>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
);
